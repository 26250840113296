import React from "react";
import {RouterProvider} from "react-router";
import {createBrowserRouter} from 'react-router-dom'
import {CatchClient} from "../components/Error/CatchClient";
import {Layout} from "../layouts/Layout";
import {TopLoadingBarLayout} from "../layouts/TopLoadingBarLayout";
import {ProtectedRoutes} from "./ProtectedRoutes";

const Login = React.lazy(() => import("../pages/login"))
const Home = React.lazy(() => import("../pages/home"))
const UserList = React.lazy(() => import("../pages/users"))
const User = React.lazy(() => import("../pages/user"))

const router = createBrowserRouter([
    {
        path: '/',
        element: <TopLoadingBarLayout/>,
        errorElement: <CatchClient/>,
        children: [
            {path: 'login', element: <Login/>},
            {
                path: '/',
                element: <ProtectedRoutes><Layout/></ProtectedRoutes>,
                children: [
                    {path: '/', element: <Home/>},
                    {path: '/users', element: <UserList/>},
                    {path: '/user/:id', element: <User/>}
                ]
            }
        ]
    }
])

export default function Router() {
    return <RouterProvider router={router}/>
}

export {router}