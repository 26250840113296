import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSignOut} from "react-auth-kit";
import {useTranslation} from "react-i18next";
import {Button} from "../../components/Actions/Button";
import {useMenu} from "../../store/useMenu";
import packageInfo from "../../utils/packageInfo";

const MenuFooter = () => {
    const {t} = useTranslation()
    const signOut = useSignOut()
    const {isCollapsed} = useMenu()

    return <div data-cy="MenuFooter">
        <div className="divider" />
        <Button onClick={signOut}
                shape="block"
                variant="ghost"
                className="flex flex-nowrap justify-between overflow-hidden">
                    <span className="whitespace-nowrap">
                        <FontAwesomeIcon icon={faRightFromBracket} /> {isCollapsed || t('logout')}
                    </span>
            {isCollapsed || <span className="text-center text-base-200/50">{packageInfo.version}</span>}
        </Button>
    </div>
}

export {MenuFooter}