import {PropsWithChildren} from "react";
import {useIsAuthenticated} from "react-auth-kit";
import {Navigate, Outlet, useLocation} from "react-router-dom";

interface ProtectedRoutesProps extends PropsWithChildren{
    redirectPath?: string
}

const ProtectedRoutes = ({redirectPath, children}: ProtectedRoutesProps) => {
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();

    return isAuthenticated()
        ? (<>{children}</> || <Outlet/>)
        : <Navigate to={redirectPath || '/login?forward=' + encodeURIComponent(location.pathname)}/>
}

export {ProtectedRoutes}

