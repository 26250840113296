import {useFilter} from "../store/useFilter";
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";

type TFilter = {
    name: string,
    values: string | Array<string | number | boolean>
}

type TUrlObserver = {
    path: string,
    filter: TFilter[],
    setter: (value: string) => void,
}

const useUrlBuilder = () => {
    const {getFilter, allFilter} = useFilter();
    const [url, setUrl] = useState<TUrlObserver>();

    const updateUrl = useCallback(
        (path: string, filter: TFilter[], setter: Dispatch<SetStateAction<string>>) =>
            setUrl({path, filter, setter}),
        []
    )

    useEffect(() => {
        allFilter()?.length && url && url.setter(buildUrl(url.path, url.filter))
    }, [allFilter()?.length, url])

    const buildUrl = (path: string, filters?: TFilter[]): string => {
        if (!filters || filters.length === 0) return path;

        const filterList = getFilter(filters.map(f => f.name));
        const queryParams = filters
            .map(f => {
                const entry = filterList?.find(e => e.name === f.name);
                if (entry) {
                    const values = Array.isArray(f.values) ? f.values.join(';') : f.values;
                    return `filter=${entry.id}:${values}`;
                }
                return null;
            })
            .filter(Boolean)
            .join('&');

        return `${path}?${queryParams}`;
    };

    return {updateUrl};
};

export default useUrlBuilder;
