import {faSadCry} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useTranslation} from "react-i18next";
import ModalLayout from "../../layouts/ModalLayout";
import {Button} from "../Actions/Button";

interface CatchUnexpectedProps {
    error: Error | null,
    resetError: () => void
}

const CatchUnexpected = ({resetError}: CatchUnexpectedProps) => {
    const {t} = useTranslation()

    return <ModalLayout>
        <h1 className="mb-4 text-center text-9xl font-bold">
            <FontAwesomeIcon icon={faSadCry} />
        </h1>
        <h1 className="mb-1 text-center text-2xl font-medium text-base-200">{t('error:exception.title')}</h1>
        <h1 className="mb-4 text-center font-medium text-1xl text-base-200">{t('error:exception.extra')}</h1>
        <Button variant="secondary"
                shape="block"
                onClick={resetError}>{t('close')}</Button>
    </ModalLayout>
}

export {CatchUnexpected}