import axios from "axios";
import _ from "lodash";
import React, {Suspense, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import LoadingBar, {LoadingBarRef} from "react-top-loading-bar";
import {api} from "../api/configs/axiosConfig";

const TopLoadingBarLayout = () => {
    const loadingBar: React.RefObject<LoadingBarRef | undefined> = React.useRef()
    const [requests, setRequests] = useState<Array<string>>([]);

    useEffect(() => {
        const request = api.interceptors.request.use(config => {
            loadingBar.current?.continuousStart()
            if (!requests.includes(config.url as string)) {
                requests.push(config.url as string)
                setRequests([...requests])
            }
            return config
        })

        const response = api.interceptors.response.use(response => {
            if (requests.includes(response.config.url as string)) {
                _.remove(requests, r => r === response.config.url as string)
                setRequests([...requests])
            }
            if (requests.length === 0) {
                loadingBar.current?.complete()
            }
            return response
        }, error => {
            if (requests.includes(error.config.url as string)) {
                _.remove(requests, r => r === error.config.url as string)
                setRequests([...requests])
            }
            loadingBar.current?.complete()
            return Promise.reject(error)
        })
        return () => {
            axios.interceptors.request.eject(request)
            axios.interceptors.response.eject(response)
        };
    }, []);


    return <div className="h-full">
        <LoadingBar className="!bg-secondary"
                    ref={loadingBar as React.RefObject<LoadingBarRef>} />
        <Suspense>
            <Outlet />
        </Suspense>
    </div>
};

export {TopLoadingBarLayout}