import {nextTick} from "process";
import {create} from 'zustand'

type TMenuState = {
    isCollapsed: boolean
    trigger: string | null
}

type TMenuActions = {
    toggleMenu: (trigger?: TMenuState['trigger']) => void
    openMenu: (trigger?: TMenuState['trigger']) => void
    closeMenu: (trigger?: TMenuState['trigger']) => void
    setTriggerAndRemove: (trigger?: TMenuState['trigger']) => void
    reset: () => void
}

const initialState: TMenuState = {
    isCollapsed: false,
    trigger: null
}

export const useMenu = create<TMenuState & TMenuActions>((set, get) => ({
    ...initialState,
    toggleMenu: (trigger) => {
        set({isCollapsed: !get().isCollapsed})
        get().setTriggerAndRemove(trigger)
    },
    openMenu: (trigger) => {
        set({isCollapsed: false})
        get().setTriggerAndRemove(trigger)
    },
    closeMenu: (trigger) => {
        set({isCollapsed: true})
        get().setTriggerAndRemove(trigger)
    },
    setTriggerAndRemove: (trigger) => {
        set({trigger: trigger || null})
        nextTick(() => set({trigger: null}))
    },
    reset: () => set(initialState)
}))