import {cva, VariantProps} from "class-variance-authority";
import {PropsWithChildren} from "react";
import {cn} from "../../utils";

const tooltipVariants = cva(
    "tooltip",
    {
        variants: {
            position: {
                top: "tooltip-top",
                right: "tooltip-right",
                bottom: "tooltip-bottom",
                left: "tooltip-left",
            }
        }
    }
)

interface TooltipProps extends PropsWithChildren, VariantProps<typeof tooltipVariants> {
    tip?: string,
    className?: string
}

const Tooltip = ({tip, className, position, children}: TooltipProps) =>
    <div data-cy="tooltip"
         className={cn(tooltipVariants({position}), className)}
         data-tip={tip}>
        {children}
    </div>

export {Tooltip}