import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Suspense, useEffect} from "react";
import {Outlet, useLocation, useSearchParams} from "react-router-dom";
import {useMenu} from "../../store/useMenu";
import {useSearch} from "../../store/useSearch";
import {cn} from "../../utils";
import {MenuCollapseButton} from "./MenuCollapseButton";
import {MenuElements} from "./MenuElements";
import {MenuFooter} from "./MenuFooter";
import {MenuHeader} from "./MenuHeader";
import {MenuSearch} from "./MenuSearch";

const Layout = () => {
    const search = useSearch()
    const [, setSearchParams] = useSearchParams()
    const location = useLocation()
    const {isCollapsed, reset} = useMenu()

    useEffect(() => {
        reset()
        search.init(location.search)
    }, []);
    useEffect(() => {
        setSearchParams(search.urlSearchParams, {preventScrollReset: true, replace: true})
    }, [search.updated]);


    return <div className="flex h-full shadow-xl">
        <div data-cy="layout"
             className={cn(
                 "flex flex-col justify-between bg-white h-full transition-all indicator p-[15px] z-50",
                 isCollapsed ? 'min-w-[80px] max-w-[80px]' : 'min-w-[250px] max-w-[250px]'
             )}>
            <MenuCollapseButton />
            <div>
                <MenuHeader />
                <MenuSearch />
                <MenuElements />
            </div>
            <MenuFooter />
        </div>
        <div className="h-full w-full overflow-scroll p-[30px] z-[1]">
            <Suspense fallback={<div className="flex h-full w-full items-center justify-center text-4xl">
                <FontAwesomeIcon icon={icon({name: 'spinner'})}
                                 spinPulse />
            </div>}>
                <Outlet />
            </Suspense>
        </div>
    </div>
}

export {Layout}