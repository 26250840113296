import {faCircleRight} from "@fortawesome/free-regular-svg-icons";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {API} from "../../api";
import {TLastSearchResponse} from "../../api/configs/ResponseTypes";
import {Button} from "../../components/Actions/Button";
import {Search} from "../../components/Custom/Search";
import {useMenu} from "../../store/useMenu";
import {useSearch} from "../../store/useSearch";

const MenuSearch = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {isCollapsed, openMenu, trigger} = useMenu()
    const search = useSearch()
    const [lastSearch, setLastSearch] =
        useState<TLastSearchResponse | undefined>();
    const triggerName = 'menu-search-button'

    useEffect(() => {
        API.lastSearch().then(({data}) => setLastSearch(data))
    }, [search.get('q')]);

    const focusInput = () => {
        openMenu(triggerName)
    }

    const setLastSearches = (entry: string) => {
        search.set('q', entry);
        goToSearch();
    }

    const goToSearch = () => {
        navigate({pathname: '/users'})
    }

    const searchRef = useCallback(
        (node: HTMLInputElement | null) => trigger === triggerName && node?.focus(),
        [trigger],
    );

    return isCollapsed
        ? <Button data-cy="menuSearchButton"
                  onClick={focusInput}
                  className="bg-base-100"
                  variant="ghost"
                  shape="block">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Button>
        : <div data-cy="menuSearch"
               className="dropdown">
            <Search ref={searchRef}
                    tabIndex={0}
                    onEnter={goToSearch}
                    info={t('info:global_search')} />
            <div data-cy="dropdown"
                 tabIndex={0}
                 className="w-full overflow-hidden bg-slate-100 shadow-lg dropdown-content z-[1] rounded-btn">
                {Array.isArray(lastSearch)
                    && <>
                        <h1 className="bg-slate-200 py-3 text-center text-sm italic">{t('last_search')}</h1>
                        <ul data-cy="list"
                            className="p-0 menu">
                            {lastSearch.map((entry, i) => <li data-cy="listEntry"
                                                              onClick={() => setLastSearches(entry)}
                                                              className="hover:font-bold"
                                                              key={`last_search_${i}`}>
                                <div className="flex justify-between">
                                    <span>{entry}</span>
                                    <FontAwesomeIcon icon={faCircleRight} />
                                </div>
                            </li>)}
                        </ul>
                    </>}
            </div>
        </div>
}

export {MenuSearch}