import {useIsAuthenticated} from "react-auth-kit";
import {useTranslation} from "react-i18next";
import {isRouteErrorResponse, Navigate, useNavigate, useRouteError} from "react-router-dom";
import ModalLayout from "../../layouts/ModalLayout";
import {Button} from "../Actions/Button";

const CatchClient = () => {
    const isAuthenticated = useIsAuthenticated()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const error = useRouteError()

    if (isRouteErrorResponse(error)) {
        // All Route errors
        return isAuthenticated() ? <ModalLayout>
            <h1 data-cy="catchClientStatus"
                className="text-center text-9xl font-bold">{error.status}</h1>
            <h1 className="mb-4 text-center text-2xl font-medium text-base-200">{t(`error:${error.status}`)}</h1>
            <Button variant="secondary"
                    shape="block"
                    onClick={() => navigate('/')}>{t('home')}</Button>
        </ModalLayout> : <Navigate to={'/login'} />
    }

    throw error
}

export {CatchClient}