import {ErrorBoundary, Provider} from "@rollbar/react";
import React from 'react';
import {AuthProvider} from 'react-auth-kit'
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom/client';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {CatchUnexpected} from "./components/Error/CatchUnexpected";
import rollbarConfig from "./configs/rollbar.config";
import reportWebVitals from './reportWebVitals';
import Router from "./router";
import './scss/main.scss'
import './utils/i18n';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const app = <AuthProvider authType="cookie"
                          authName="_auth"
                          cookieDomain={window.location.hostname}
                          cookieSecure={window.location.protocol === "https:"}>
    <Provider config={rollbarConfig}>
        <ErrorBoundary fallbackUI={CatchUnexpected}>
            <ToastContainer theme="colored"/>
            <Router/>
        </ErrorBoundary>
    </Provider>
</AuthProvider>

root.render(
    process.env.REACT_APP_ENV === 'local' ? (
        <React.StrictMode>
            {app}
        </React.StrictMode>
    ) : (
        app
    )
)
reportWebVitals();
