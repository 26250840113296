import {ReactNode} from "react";
import {matchPath, NavLink as ReactNavLink, NavLinkProps as ReactNavLinkProps, To, useLocation} from "react-router-dom";

interface NavLinkProps extends Omit<ReactNavLinkProps, 'to'> {
    disabled?: boolean,
    to?: To,
    'data-cy'?: string
}

const NavLink = ({disabled, onClick, children, to, ...props}: NavLinkProps) => {
    const active = matchPath(to?.toString() || '!', useLocation()['pathname'])
    const className = typeof props?.className === 'function'
        ? props?.className({isActive: !!active, isPending: false, isTransitioning: false})
        : props?.className;

    return disabled || onClick || !to
        ? <span data-cy={props["data-cy"]} className={className} {...{onClick}}>{children as ReactNode}</span>
        : <ReactNavLink to={to as To} {...props}>{children}</ReactNavLink>
}

export {NavLink}