import {cva, VariantProps} from "class-variance-authority";
import {ButtonHTMLAttributes} from "react";
import {cn} from "../../utils";

const buttonVariants = cva(
    "btn",
    {
        variants: {
            variant: {
                default: "",
                neutral: "btn-neutral",
                primary: "btn-primary",
                error: "btn-error",
                light: "btn-light",
                secondary: "btn-secondary",
                ghost: "btn-ghost",
                link: "btn-link",
                glass: "glass",
            },
            outline: {
                false: "",
                true: "btn-outline"
            },
            size: {
                xs: "btn-xs",
                sm: "btn-sm",
                md: "",
                lg: "btn-lg",
            },
            shape: {
                block: "btn-block",
                square: "btn-square",
                circle: "btn-circle"
            }
        },
        defaultVariants: {
            outline: false,
            variant: "default"
        }
    }
)

interface ButtonProps
    extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color" | "size">,
        VariantProps<typeof buttonVariants> {
}

const Button = ({variant, outline, size, shape, className, children, ...props}: ButtonProps) => {
    return <button data-cy="button" className={cn(buttonVariants({
        variant,
        outline,
        size,
        shape,
        className
    }))} {...props}>{children}</button>
}


export {Button, buttonVariants}