import React, {PropsWithChildren} from "react";

const Prefix = ({children}: PropsWithChildren) => <>{children}</>
const Suffix = ({children}: PropsWithChildren) => <>{children}</>

const Slots = {
    Prefix,
    Suffix
}

export default Slots

export {Prefix, Suffix}