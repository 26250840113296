import {de} from 'date-fns/locale'
import i18n from "i18next";
import {registerLocale} from "react-datepicker";
import {initReactI18next} from "react-i18next";
import countryDe from './../locales/de/country.json'
import errorDE from './../locales/de/error.json'
import formDE from './../locales/de/form.json'
import infoDe from './../locales/de/info.json'
import stepDe from './../locales/de/step.json'
import translationDE from './../locales/de/translation.json'
import attributesDE from './../locales/de/attributes.json'

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
    resources: {
        de: {
            translation: translationDE,
            form: formDE,
            error: errorDE,
            info: infoDe,
            step: stepDe,
            country: countryDe,
            attributes: attributesDE,
        }
    },
    interpolation: {
        skipOnVariables: false
    },
    ns: ['translation', 'form', 'error', 'info', 'step', 'country', 'attributes'],
    defaultNS: 'translation',
    lng: "de",
    fallbackLng: "de"
}).catch(e => {
    throw e
});

registerLocale('de', de)

export default i18n