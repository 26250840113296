import {useAuthUser} from "react-auth-kit";
import {useMenu} from "../../store/useMenu";
import {nameShortener} from "../../utils";
import Logo from './../../assets/image/strawberry.png'

const MenuHeader = () => {
    const auth = useAuthUser()
    const {isCollapsed} = useMenu()

    return <div data-cy="menuHeader"
                className="flex content-between items-center overflow-hidden min-h-[4rem] pl-[7px]">
        <img className="mr-1"
             src={Logo}
             width={40}
             height={40} />
        {isCollapsed || <div data-cy="content"
                             className="whitespace-nowrap">
            <p data-cy="name"
               className="font-bold">{nameShortener(`${auth()?.firstname} ${auth()?.name}`, 18)}</p>
            <p data-cy="group">{auth()?.role}</p>
        </div>}
    </div>
}

export {MenuHeader}