import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "../../components/Actions/Button";
import {useMenu} from "../../store/useMenu";

const MenuCollapseButton = () => {
    const {isCollapsed, toggleMenu} = useMenu()

    return <div data-cy="menuCollapseButton"
                className="top-10 indicator-item">
        <Button onClick={() => toggleMenu()}
                variant="secondary"
                shape="circle"
                size="xs">
            <FontAwesomeIcon icon={isCollapsed
                ? faChevronRight
                : faChevronLeft} />
        </Button>
    </div>
}

export {MenuCollapseButton}