import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {cva, VariantProps} from "class-variance-authority";
import React, {InputHTMLAttributes, useState} from "react"
import {FieldError} from "react-hook-form";
import {cn} from "../../utils";
import {Button} from "../Actions/Button";
import Slots from "../utils/Slots";

const textInputVariants = cva(
    "picker-input form-control",
    {
        variants: {
            variant: {
                bordered: "picker-input-bordered",
                ghost: "picker-input-ghost",
            },
            color: {
                primary: "picker-input-primary",
                secondary: "picker-input-secondary",
                error: "picker-input-error",
            },
            size: {
                xs: "picker-input-xs",
                sm: "picker-input-sm",
                md: "picker-input-md",
                lg: "picker-input-lg",
            }
        },
        defaultVariants: {
            variant: "bordered"
        }
    }
)

export interface TextInputProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, "color" | "size">,
        VariantProps<typeof textInputVariants> {
    label?: string
    labelAlt?: string
    labelBottom?: string
    labelBottomAlt?: string
    error?: FieldError
}

const TextInput =
    React.forwardRef<HTMLInputElement, TextInputProps>(
        (
            {
                label = null,
                labelAlt = null,
                labelBottom = null,
                labelBottomAlt = null,
                type = 'text',
                error,
                className,
                variant,
                color,
                size,
                children,
                ...props
            },
            ref
        ) => {
            const [show, setShow] = useState(false);
            let prefix;
            let suffix = type === 'password'
                && <Button data-cy="passwordEye"
                           type="button"
                           outline={false}
                           variant="ghost"
                           onClick={() => setShow(!show)}>
                    <FontAwesomeIcon icon={show ? faEyeSlash : faEye} />
                </Button>

            React.Children.forEach(children, (child) => {
                if (!React.isValidElement(child)) return;
                switch (child.type) {
                    case Slots.Prefix:
                        prefix = child
                        break;
                    case Slots.Suffix:
                        suffix = child
                        break;
                }
            })

            return <div data-cy="textInput"
                        className={cn(textInputVariants({
                            variant,
                            color: error ? 'error' : color,
                            size,
                            className
                        }))}>
                {(label || labelAlt) && <label className="label">
                    {label && <span className="label-text">{label}</span>}
                    {labelAlt && <span className="label-text-alt">{labelAlt}</span>}
                </label>}
                <div className="relative">
                    {prefix && <div data-cy="prefix"
                                    className="picker-input-prefix">{prefix}</div>}
                    <input type={show ? 'text' : type}
                           ref={ref}
                           className={props.disabled ? "cursor-not-allowed" : ""}
                           {...props} />
                    {suffix && <div data-cy="suffix"
                                    className="picker-input-suffix">{suffix}</div>}
                </div>
                {(labelBottom || labelBottomAlt || error) && <label className="label">
                    {(labelBottom || error) && <span className="label-text-alt">{error?.message || labelBottom}</span>}
                    {labelBottomAlt && <span className="label-text-alt">{labelBottomAlt}</span>}
                </label>}
            </div>
        }
    )

export {TextInput, textInputVariants}