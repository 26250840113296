import {PropsWithChildren} from "react";

function ModalLayout({children}: PropsWithChildren) {
    return (
        <div className="flex h-screen flex-col justify-center overflow-hidden">
            <div className="relative m-auto w-full rounded-md p-6 shadow-md bg-neutral lg:max-w-lg">
                {children}
            </div>
        </div>
    )
}

export default ModalLayout